// Aggregator News

$agg-news-element-entry-title-font: $font-primary;
$agg-news-element-entry-title-size: 24px;
$agg-news-element-entry-title-weight: 700;
$agg-news-element-entry-title-line-height: 1.5em;
$agg-news-element-entry-title-color: $color-primary;
$agg-news-element-entry-title-color-hover: $color-primary;
$agg-news-element-entry-title-case: null;

$agg-news-element-entry-meta-font: $font-primary;
$agg-news-element-entry-meta-size: 12px;
$agg-news-element-entry-meta-weight: 600;
$agg-news-element-entry-meta-line-height: 18px;
$agg-news-element-entry-meta-color: $color-gray-lt;
$agg-news-element-entry-meta-case: null;
$agg-news-element-entry-meta-padding: 5px 0px 0px 0px;

$agg-news-element-entry-body-font: $font-primary;
$agg-news-element-entry-body-size: 14px;
$agg-news-element-entry-body-weight: 500;
$agg-news-element-entry-body-line-height: 1.5;
$agg-news-element-entry-body-color: $color-gray;
$agg-news-element-entry-body-case: null;
$agg-news-element-entry-body-padding: 5px 0px 0px 0px;

$agg-news-element-entry-preview-image-width: 213px;
$agg-news-element-entry-preview-image-aspect-ratio: aspect-to-percent(4, 3);
$agg-news-element-entry-preview-image-margin: 0 15px 0 0;
$agg-news-element-entry-preview-image-float: true;

$agg-news-element-entry-button-padding: 10px 0px 0px 0px;
$agg-news-element-entry-padding-y: 15px;

// RESETS
.newsAggregatorElement{
  .condensed ul{
    list-style: none;
    @media screen and (max-width: 1024px) {
      display: flex;
      flex-flow: column;
      width: 100%;
    }
    li:empty{ display: none; }
  }
}


// List Styles
.newsAggregatorElement{
  .condensed,
  .expanded{
    padding: $agg-news-element-entry-padding-y 20px;
    display: flex;
    flex-flow: row;
    align-items: center;
    @media screen and (max-width: 1024px) {
      padding: $agg-news-element-entry-padding-y 5px;
    }
  }
  .aggHeader + .condensed,
  .aggHeader + .expanded {
    margin-top: 15px;
    @media screen and (max-width: $breakpoint-sm-max) {
      margin-top: 0;
    }
  }
}


.newsAggregatorElement{
  // ENTRY PREVIEW IMAGE
  .condensed > a{
    @include clearfix;
    display: block;
    position: relative;
    width: 100%;
    max-width: $agg-news-element-entry-preview-image-width;
    margin: $agg-news-element-entry-preview-image-margin;
    @media screen and (max-width: 1024px) {
      max-width: 120px;
    }
    @if $agg-news-element-entry-preview-image-float == true{ float: left; }
    &:before{
      content: "";
      float: left;
      padding-bottom: $agg-news-element-entry-preview-image-aspect-ratio;
      @media screen and (max-width: 1024px) {
        padding-bottom: aspect-to-percent(1, 1);
      }
    }
    img{
      position: absolute;
      width: 100% !important;
      height: 100% !important;
      object-fit: cover;
      object-position: center;
      border-radius: 10px;
    }
  }

  // ENTRY TITLE
  .condensed .details h4 a{
    font-family: $agg-news-element-entry-title-font;
    font-size: $agg-news-element-entry-title-size;
    font-weight: $agg-news-element-entry-title-weight;
    line-height: $agg-news-element-entry-title-line-height;
    text-transform: $agg-news-element-entry-title-case;
    color: $agg-news-element-entry-title-color;
    @media screen and (max-width: 1024px) {
      font-size: 16px;
    }
    &:hover{
      text-decoration: underline;
      font-weight: 700;
    }
  }

  // ENTRY META
  .condensed .dateAuthor{
    line-height: $agg-news-element-entry-meta-line-height;
    padding: $agg-news-element-entry-meta-padding;
  }
  .condensed .newsAuthor,
  .condensed .newsDate{
    font-family: $agg-news-element-entry-meta-font;
    font-size: $agg-news-element-entry-meta-size;
    font-weight: $agg-news-element-entry-meta-weight;
    line-height: $agg-news-element-entry-meta-line-height;
    text-transform: $agg-news-element-entry-meta-case;
    color: $agg-news-element-entry-meta-color;
  }

  // ENTRY BODY (teaser)
  .condensed .teaser{
    font-family: $agg-news-element-entry-body-font;
    font-size: $agg-news-element-entry-body-size;
    font-weight: $agg-news-element-entry-body-weight;
    line-height: $agg-news-element-entry-body-line-height;
    text-transform: $agg-news-element-entry-body-case;
    color: $agg-news-element-entry-body-color;
    padding: $agg-news-element-entry-body-padding;
    @media screen and (max-width: 1024px) {
      display: none;
    }
  }

  // ENTRY BUTTON
  .condensed .commentsAndMore{
    padding: 0;
    @media screen and (max-width: 1024px) {
      padding: $agg-news-element-entry-button-padding;
    }
    .button-content {
      display: inline-block;
    }
    .button-commentCount {
      display: inline-block;
      padding: 0;
    }
    .button-image-right{ display: none; }
  }
  .condensed .readMore .button-small{
    display: none;
    @media screen and (max-width: 1024px){
      display: inline-block;
      text-transform: none;
      width: 200px;
      padding: 6.5px 10px;
      text-align: center;
      float: right;
    }
  }
}


// EXPANDED
.newsAggregatorElement{
  // ENTRY PREVIEW IMAGE
  .expanded .newsItemHeader > a {
    @include clearfix;
    display: block;
    position: relative;
    width: 100%;
    max-width: $agg-news-element-entry-preview-image-width;
    margin: $agg-news-element-entry-preview-image-margin;
    @if $agg-news-element-entry-preview-image-float == true{ float: left; }
    &:before{
      content: "";
      float: left;
      padding-bottom: $agg-news-element-entry-preview-image-aspect-ratio;
    }
    img{
      position: absolute;
      width: 100% !important;
      height: 100% !important;
      object-fit: cover;
      object-position: center;
      border-radius: 10px;
    }
  }

  // ENTRY TITLE
  .expanded .newsItemHeader h2 a {
    display: block;
    font-family: $agg-news-element-entry-title-font;
    font-size: $agg-news-element-entry-title-size;
    font-weight: $agg-news-element-entry-title-weight;
    line-height: $agg-news-element-entry-title-line-height;
    text-transform: $agg-news-element-entry-title-case;
    color: $agg-news-element-entry-title-color;
    transition: font-weight $transition-linear;
    &:hover{
      text-decoration: underline;
      font-weight: 700;
    }
  }

  // ENTRY META
  .expanded .dateAuthor{
    line-height: $agg-news-element-entry-meta-line-height;
    padding: $agg-news-element-entry-meta-padding;
  }
  .expanded .newsAuthor,
  .expanded .newsDate{
    font-family: $agg-news-element-entry-meta-font;
    font-size: $agg-news-element-entry-meta-size;
    font-weight: $agg-news-element-entry-meta-weight;
    line-height: $agg-news-element-entry-meta-line-height;
    text-transform: $agg-news-element-entry-meta-case;
    color: $agg-news-element-entry-meta-color;
  }

  // ENTRY BODY (teaser)
  .expanded .newsItemElement > h4 {
    font-family: $agg-news-element-entry-body-font;
    font-size: $agg-news-element-entry-body-size;
    font-weight: $agg-news-element-entry-body-weight;
    line-height: $agg-news-element-entry-body-line-height;
    text-transform: $agg-news-element-entry-body-case;
    color: $agg-news-element-entry-body-color;
    padding: $agg-news-element-entry-body-padding;
  }

  // ENTRY BUTTON
  .expanded .commentsAndMore{
    margin: 0;
    .commentCount{
      padding: $agg-news-element-entry-button-padding;
      display: inline-block;
    }
    .button-content{
      display: inline-block;
    }
    .button-commentCount {
      display: inline-block;
      padding: 0;
    }
    .button-image-right{ display: none; }
  }
  .expanded .readMore .button-small{
    @include global-link-style;
    @include global-link-container;
    @include global-link-text($align: center);
    display: inline-block;
  }
}

// AGG CONTAINER STYLES
.custom-agg-container {
  padding-top: 75px;
  padding-bottom: 90px;
  background-color: transparent;
  @media screen and (max-width: $breakpoint-sm-max) {
    padding-top: 15px;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .extendedOptions {
    padding-right: 20px;
    @media screen and (max-width: $breakpoint-sm-max) {
      padding-right: 7.5px;
    }
    @media screen and (min-width: $breakpoint-md-min) {
      padding-right: 15px;
      a {
        padding: 5px 15px;
      }
    }
  }
  .column {

    @media screen and (max-width: $breakpoint-sm-max) {
      padding-left: 0;
      padding-right: 0;
    }

    .newsAggregatorElement,
    .eventAggregatorElement {
      background: #fff;
      padding-top: 10px;
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 30px;
      border-radius: 10px;
      @media screen and (max-width: $breakpoint-sm-max) {
        border-radius: 0;
        margin-bottom: 0;
        margin-top: 0;
        padding-right: 15px;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .col-md-4 {
    .newsAggregatorElement {
      .condensed ul {
        display: flex;
        flex-flow: column;
      }
      .condensed .details h4 a {
        font-size: 22px;
      }
      .condensed .teaser {
        display: none;
      }
      .condensed .readMore .button-small {
        display: inline-block;
        text-transform: none;
        width: 200px;
        padding: 6.5px 10px;
        text-align: center;
      }
      .condensed > a {
        display: none;
      }
      .condensed .dateAuthor {
        padding: 10px 0 15px 0;
      }
    }
  }
}
