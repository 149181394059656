// Global Page Element Headers
.pageElement > h3:first-child{
  @include page-element-header;
}
.pageElement > h3:only-child{
  margin-bottom: 0;
}

.documentElement {
  .pdf {
    &:after {
      transform: translateX(2px) !important;
    }
  }
}