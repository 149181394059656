// Use with full-width-container class to make columns flush left/right

@media only screen and (min-width: 768px) {
  .user_mode {
    .columns-33-33-33-flush {
      padding-right: 0;

      &.full-width-container .col-md-4 {
        max-width: calc(1200px / 3 + 10px);
      }

      .column {
        padding-left: 0;
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .user_mode {
    .columns-33-33-33-flush {
      width: 100%;
      max-width: 100%;
      padding-left: 30px;
      padding-right: 0;

      .column {
        padding-left: 0;
        padding-right: 30px;
      }
    }
  }
}


@media only screen and (min-width: 768px) {
  .user_mode {
    .columns-66-33-flush {

      padding-right: 0;

      &.full-width-container {
        justify-content: space-between;

        .col-md-8 {
          max-width: calc(1200px / 3 * 2 + 20px);
        }

        .col-md-4 {
          max-width: calc(1200px / 3 + 10px);
        }
      }

      .column {
        padding-left: 0;
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .user_mode {
    .columns-66-33-flush {
      width: 100%;
      max-width: 100%;
      padding-left: 30px;
      padding-right: 0;

      &.full-width-container {
        justify-content: center;

        .col-md-8 {
          //max-width: calc((1200px / 3 * 2));
          padding-right: 30px;
        }
      }

      .column {
        padding-left: 0;
        padding-right: 30px;
      }
    }
  }
}