.custom-agg-container .column-2 {

  .pageEl {
    background: white;
    padding: 0 10px;

  }

  .pageElement {
    padding-left: 10px;
    padding-right: 10px;

    &:first-of-type {
      margin-top: 10px;
    }

    &:last-of-type {
      padding-bottom: 20px;
    }
  }

  .textBlockElement {
    padding-left: 0;
    padding-right: 0;

    .text {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

}

@media only screen and (min-width: 768px) {
  .custom-agg-container .column-2 {
    padding-top: 15px;

    .pageElement {

      &:last-of-type {
        padding-bottom: 5px;
      }
    }

    .rounded-borders-only-top {
      border-radius: 10px 10px 0 0;
    }

    .rounded-borders-only-bottom {
      border-radius: 0 0 10px 10px;
    }

    .rounded-borders-all {
      border-radius: 10px;
    }
  }
}