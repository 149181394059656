/* Single Image Element */

.heroPhotoElement {
  img {
    display: block;
    width: 100%;
    margin: 0 auto;
    border-radius: 10px;
  }
  .cutline {
    margin: 0;
    width: 100%;
  }
}
