.user_mode {
    .hero-video-section {
      position: relative;
      padding: 0;
      margin: 0;
      max-width: 100%;
      overflow: hidden;
      .column {
        padding: 0;
        margin: 0;
      }
      .pageElement {
        padding: 0;
        margin: 0;
      }
      .codeElement {
        height: 0;
        padding-bottom: 56.25%;
        &:before {
            content: '';
            background: $color-primary;
            opacity: 0.3;
            position: absolute;
            bottom: 0; top: 0; right: 0; left: 0;
            z-index: 1;
            @media screen and (max-width: $breakpoint-sm-max) {
                display: none;
            }
        }
      }
      #heroVideo {
        position: relative;
        width: 100%;
        height: auto;
        max-height: 1200px;
      }
      .hero-video-title {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        display: block;
        margin: 6.05% 5.4%;
        z-index: 1;
        @media screen and (max-width: $breakpoint-sm-max) {
            position: relative;
            margin: 0;
            padding-bottom: 30px;
        }
        .text * {
          color: white;
          @media screen and (max-width: $breakpoint-sm-max) {
            color: $color-primary;
        }
        }
        h3 {
          color: #fff;
          font-size: 48px;
          font-weight: bold;
          text-shadow: 0 4px 0 $color-primary;
          line-height: 1.25em;
          max-width: 710px;
          @media screen and (max-width: 1024px) {
              font-size: 36px;
              max-width: 400px;
          }
          @media screen and (max-width: $breakpoint-sm-max) {
            color: $color-primary;
            font-size: 28px;
            text-shadow: none;
            padding-left: 5px;
            padding-top: 15px;
        }
          &:after {
              display: none;
          }
        }
        p {
            font-size: 24px;
            line-height: 1.5em;
            font-weight: 500;
            padding-left: 10px;
            max-width: 740px;
            @media screen and (max-width: 1024px) {
                font-size: 20px;
                max-width: 550px;
            }
            @media screen and (max-width: $breakpoint-sm-max) {
                font-size: 18px;
                padding-left: 15px;
                font-weight: 300;
            }
        }
      }
    }
  }