.registration-section {
    background: $color-primary;
    padding: 19px 0;
    .column {
        display: flex;
        justify-content: center;
        @media screen and (max-width: 1024px) {
            flex-flow: column;
            align-items: center;
        }
        .textBlockElement {
            p {
                color: #fff;
                font-size: 24px;
                font-weight: 500;
                @media screen and (max-width: $breakpoint-sm-max) {
                    text-align: center;
                    padding: 0 10px;
                }
            }
        }
        .reg-button {
            padding-left: 30px;
            @media screen and (max-width: 1024px) {
                padding-left: 0;
            }
            h4 {
                a {
                    font-size: 18px;
                    width: 200px;
                    height: 40px;
                    display: flex;
                    justify-content: center;
                }
            }
        }
    }
}