.sponsors-container {
  max-width: 100% !important;
  overflow: hidden;

  .heroPhotoElement {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 140px;
  }
}

@media only screen and (min-width: 1024px) {
  .sponsors-container {
    .heroPhotoElement {
      min-height: 120px;
    }
  }
}