// Sponsors Slider
.sponsors-slider {
  background: #fff;
  padding: 0 35px;

  .slick-slider {
    .slick-prev:before,
    .slick-next:before {
      color: black;
      font-size: 41px;
      font-weight: bold;
    }

    .slick-next:before {
      content: '\f105';
      font-family: FontAwesome;
    }

    .slick-prev:before {
      content: '\f104';
      font-family: FontAwesome;
    }

    .slick-slide {
      .heroPhotoElement {
        img {
          padding: 10px;
          max-height: 80px;
          max-width: 200px;
          width: auto;
          transition: transform $transition-linear;
        }
      }

      &:hover {
        img {
          transform: scale(1.1);
        }
      }
    }

    .slick-track {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 100%;
    }
  }

  .column {
    padding: 0;
  }

  .edit_mode & {
    .heroPhotoElement {
      img {
        max-height: 100px;
        width: auto;
      }
    }
  }


}

@media only screen and (min-width: 1024px) {

  .sponsors-slider {

    padding: 0 55px;
  }
}
