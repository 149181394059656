.custom-footer-extra {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  background-color: $color-primary;
  color: white;
  padding-top: 0;
  padding-bottom: 15px;

  .footer-logo {
    padding-bottom: 5px;
    padding-top: 20px;

    .heroPhotoElement {
      max-width: 165px;
    }
  }

  .footer-nav {
    padding-bottom: 15px;

    .text {
      ul {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        list-style-type: none;

        li {
          a {
            color: white;
            font-weight: 500;
            line-height: 2.4;
            padding: 0 23px;
            text-align: center;
            white-space: nowrap;
            transition: color $transition-linear;

            &:hover {
              text-decoration: none;
              color: $color-secondary;
            }
          }
        }
      }
    }
  }

  .footer-button {
    .linkElement {
      a {
        text-transform: uppercase;
        font-size: 18px;
        text-align: center;
        line-height: 1.7;
        height: 40px;
        justify-content: center;
        min-width: 200px;
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .custom-footer-extra {
    padding-bottom: 0;
    .footer-logo {
      padding-top: 40px;
    }
  }
}


// Edit mode UI
.custom-footer-extra {
  @include edit_ui_element($padding: 10px);

  &:empty {
    display: none;
  }
}