.social-media-container {
  background: #59B443 url(../images/custom/SocialContainer.png) left top no-repeat;
  background-size: cover;
  //padding: 0 7.5px 15px 15px;
  padding-top: 0;
  padding-bottom: 15px;

  .social-media-title {
    flex-basis: 100%;
    padding-left: 7.5px;
    padding-right: 7.5px;

    .pageEl {
      display: flex;
    }

    .pageElement {
      max-width: $content-area-width;
      margin-left: auto;
      margin-right: auto;

      &>h3:first-child {
        font-size: 38px;
        color: white;
        transform: none;

        &:after {
          background-color: white;
          transform: none;
        }
      }
    }

    .sn-social-media-list {
      margin-top: 0;
      transform: translateX(-25px);

      .sn-social-media-icon {
        margin: 0 12px;
        transition: color $transition-linear;

        &.icon-twitter,
        &.icon-facebook,
        &.icon-youtube,
        &.icon-instagram,
        &.icon-facebook,
        &.icon-email,
        &.icon-vimeo,
        &.icon-flickr {
          background: transparent !important;
        }

        &:before {
          font-size: 30px;
        }

        &:hover {
          color: $color-primary;
        }
      }
    }
  }

  .column {
    padding-bottom: 15px;

    .pageElement {
      border-radius: 10px;
      padding: 10px;
      background: white;
      text-align: center;

    }


  }
}

@media only screen and (min-width: 768px) {

  .social-media-container {
    .column {
      .pageElement {
        min-height: 540px;
      }
    }

    .pageElement {
      &>h3:first-child {
        span {
          padding-left: 10px;
        }
      }
    }

    .tweets {
      max-height: 375px;
    }

  }
}

@media only screen and (min-width: 1024px) {

  .user_mode {

    .social-media-container {

      padding-top: 60px;
      padding-bottom: 30px;
      padding-left: 30px;

      .social-media-title {
        padding: 0 30px 0 0;

        .pageEl:last-child {
          text-align: right;
          transform: translate(-10px, -70px);
        }

        .sn-social-media-list {
          transform: translateX(0);
        }
      }

      .column {
        transform: translateY(-30px);
      }
    }
  }
}